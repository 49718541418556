import $ from 'jquery';

import slick from 'slick-carousel';
import Modal from '../modal/modal';
import Ukiyo from "ukiyojs";

require('intersection-observer');

export default class Main {

	constructor(params) {

        this.carousel = document.querySelector('[data-main-carousel]');
		this.tagline = document.querySelector('[data-main-tagline]');
		this.slider = document.querySelector('[data-main-slider]');

	}

	init() {

		this._splashInit();

        this._mainCarouselInit();
		this._mainSliderInit();

		this._mainAboutInit();

		this._mainTaglineInit();

	}

	_splashInit() {

		if(!this.getCookie('splashShowed')) {

			const splash = document.querySelector('[data-main-splash]');
			if(!splash) return false;

			splash.classList.add('show')

			const svgs = [].slice.call(splash.querySelectorAll('[data-main-splash-svg]')),
				title = splash.querySelector('[data-main-splash-title]');

			setTimeout(function(){
				title.classList.add('show');
			}, 500);

			setTimeout(function(){
				splash.classList.add('blink');
				setTimeout(function(){
					splash.classList.add('unblink');
				}, 500);
			}, 2000);

			setTimeout(function(){
				svgs.forEach(svg => {
					svg.classList.add('hide');
				});
			}, 2750);

			setTimeout(function(){
				title.classList.add('no-opacity');
			}, 3000);

			setTimeout(function(){
				splash.classList.add('transition');
				splash.classList.remove('show');
				setTimeout(function(){
					splash.remove();
				}, 1500);
			}, 3750);

			this.setCookie('splashShowed', 'true', 1);

		}

	}

    _mainCarouselInit() {
        if(!this.carousel) return false;

		let that = this;

		setTimeout(function(){

	        let carouselSlick = $(that.carousel).slick({
	            autoplay: true,
				autoplaySpeed: 5000,
	            slidesToShow: 1,
	            slidesToScroll: 1,
	            infinite: true,
	            arrows: true,
	            prevArrow: document.querySelector('.main__carousel-arrow--prev'),
	            nextArrow: document.querySelector('.main__carousel-arrow--next'),
	            dots: true,
	            appendDots: that.carousel.parentNode
	        });

			carouselSlick.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				if(currentSlide !== nextSlide) {
					that.lazyLoadBackgroundImages($(slick.$slides.get(nextSlide)));
				}
			});

		}, 5000);

		let hides = [].slice.call(this.carousel.querySelectorAll('.hide'));
		if(hides.length) {
			hides.forEach(hide => {
				hide.classList.remove('hide');
			});
		}

    }

	_mainSliderInit() {
		if(!this.slider) return false;

		let that = this;

        $(this.slider).slick({
            autoplay: true,
			autoplaySpeed: 3000,
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            arrows: true,
			dots: true,
            prevArrow: document.querySelector('.main__slider-arrow--prev'),
            nextArrow: document.querySelector('.main__slider-arrow--next'),
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2
					}
				}
			]
        });
	}

	_mainAboutInit() {
		let container =  document.querySelector('[data-main-about]'),
			image = document.querySelector('[data-main-about-image]');
		if(!container || !image) return false;

		new Ukiyo(image, {
			speed: 2,
			scale: 1.5
		});

	}

	_mainTaglineInit() {
		if(!this.tagline) return false;

		const options = { root: null, rootMargin: '0px', threshold: 1.0 };

        const OBSERVER = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    entry.target.classList.add('on-display');
                    OBSERVER.unobserve(entry.target);
                }
            });
        }, options);

        OBSERVER.observe(this.tagline);

	}

	lazyLoadBackgroundImages(el) {
		let items = [].slice.call(document.querySelectorAll('[data-main-carousel-item]'));
		if(!items.length || !el[0]) return false;

		let element = el[0].querySelector('[data-main-carousel-item]');

		items.forEach(item => {
			if(!element.classList.contains('is-lazy-loaded')) {
				element.classList.add('is-lazy-loaded');
			}
		})

	}

	getCookie(name) {
		let nameEQ = name + "=";
		let ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			let c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}

	setCookie(name,value,days) {
		let expires = "";
		if (days) {
			let date = new Date();
			date.setTime(date.getTime() + (days*24*60*60*1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	}

}
