'use strict';

import $ from 'jquery';

import { Forms } from './libs/form';

import Modal from '../_modules/modal/modal';

import Header from '../_modules/header/header';
import Main from '../_modules/main/main';
import Catalog from '../_modules/catalog/catalog';
import Product from '../_modules/catalog/product';
import ContentHeader from '../_modules/content-header/content-header';

(function(w, d) {

	function initForms() {
		new Forms({ root: 'data-form' });
	}

	function initModalSettings() {
		$.modal.defaults = {
			closeExisting: true,
			escapeClose: false,
			clickClose: true,
			closeText: 'Закрыть',
			closeClass: '',
			showClose: true,
			modalClass: "modal",
			blockerClass: "jquery-modal",
			spinnerHtml: '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div>',
			showSpinner: true,
			fadeDuration: 250,
			fadeDelay: 1.0
		};
	}

	function initModal() {
		const modal = new Modal();
		modal.init();
	}

	function reInitModal() {
		$(document).on('modal:open', function(event, modal) {
			initForms();
		});
	}

	function initHeader() {
		const header = new Header();
		header.init();
	}

	function initMain() {
		const main = new Main();
		main.init();
	}

	function initCatalog() {
		const catalog = new Catalog();
		catalog.init();
	}

	function initProduct() {
		const product = new Product();
		product.init();
	}

	function initContentHeader() {
		const contentHeader = new ContentHeader();
		contentHeader.init();
	}

	w.addEventListener('load', e => {

		initForms();

		initModalSettings();
		initModal();
		reInitModal();

		initHeader();

		initMain();
		initCatalog();
		initProduct();

		initContentHeader();

	});

}(window, document));
