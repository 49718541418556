import $ from 'jquery';

export default class Header {

	constructor(params) {

	}

	init() {

		this._headerContainerPositionInit();

		this.headerNavbarToggleButtonHandler();

	}

	_headerContainerPositionInit() {
		const toolbar = document.querySelector('[data-toolbar]'),
			  header = document.querySelector('[data-header]');
		if(!toolbar || !header) return false;

		const toolbarHeight = toolbar.offsetHeight,
			  headerHeight = header.offsetHeight,
			  headerBottomMargin = getComputedStyle(header);

		window.addEventListener('scroll', event => {
			if($(window).scrollTop() > toolbarHeight) {
				header.classList.add('fixed');
				toolbar.setAttribute('style', 'margin-bottom:' + ( headerHeight + parseInt(headerBottomMargin.marginBottom) ) + 'px');
			} else {
				header.classList.remove('fixed');
				toolbar.removeAttribute('style');
			}

		});

	}

	headerNavbarToggleButtonHandler() {
		let button = document.querySelector('[data-header-navbar-toggle]');
		if(!button) return false;

		button.addEventListener('click', e => {
			e.preventDefault();
			let navbar = document.getElementById(button.getAttribute('data-header-navbar-toggle').replace('#', ''));
			if(!navbar) return false;

			$(navbar).slideToggle();

		});

	}

}
