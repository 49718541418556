import $ from 'jquery';
window.jQuery = $;
import 'lazysizes';
require('@fancyapps/fancybox');

export default class Catalog {

	constructor(params) {
        this.catalog = document.querySelector('[data-catalog]');
        this.filter = document.querySelector('[data-filter]');
        this.checkboxes = [].slice.call(document.querySelectorAll('[data-filter-checkbox]'));
	}

	init() {
        if(!this.catalog) return false;

        this._catalogFilterCheckboxesHandler();

		this._fancyboxInit();

	}

	_fancyboxInit() {
        $.fancybox.defaults.toolbar = false;
        $.fancybox.defaults.infobar = false;
        $.fancybox.defaults.thumbs.autoStart = false;
		$.fancybox.defaults.buttons = [ 'close' ];
    }

    _catalogFilterCheckboxesHandler() {

        let that = this;

        this.checkboxes.forEach(checkbox => {
            checkbox.addEventListener('click', event => {
                event.preventDefault();
                $(checkbox).children('input').click();
                $(this.catalog).preloader('start');
                setTimeout(function(){
                    $(that.catalog).preloader('stop');
                }, 1000);
            });
        });

    }

}
