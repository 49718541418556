import $ from 'jquery';
window.jQuery = $;
import slick from 'slick-carousel';

require('@fancyapps/fancybox');

export default class Product {

    constructor(params) {

	}

	init() {

        this._productCarouselInit();

        this._fancyboxInit();

	}

    _fancyboxInit() {
        $.fancybox.defaults.toolbar = true;
        $.fancybox.defaults.infobar = false;
        $.fancybox.defaults.thumbs.autoStart = false;
        $.fancybox.defaults.buttons = [ 'close' ];
    }

    _productCarouselInit() {
        let carousel = document.querySelector('[data-product-carousel]');
        if(!carousel) return false;

        $(carousel).slick({
            autoplay: true,
			autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            arrows: true,
            prevArrow: document.querySelector('.product__carousel-arrow--prev'),
            nextArrow: document.querySelector('.product__carousel-arrow--next'),
            dots: true,
            appendDots: carousel.parentNode
        });

    }

}
