export default class ContentHeader {

	constructor(params) {

        this.contentHeaderFeatures = [].slice.call(document.querySelectorAll('[data-content-header-feature]'));

	}

	init() {

        this._contentHeaderFeaturesInit();

	}

    _contentHeaderFeaturesInit() {
        if(!this.contentHeaderFeatures.length) return false;

        let that = this,
            timeout = 0;

        this.contentHeaderFeatures.forEach(element => {

            if(timeout == 0) {

                element.classList.add('show');
                timeout++;

            } else {

                setTimeout(function(){
                    element.classList.add('show');
                }, 400 * timeout);
                timeout++;

            }

        });

    }

}
