import $ from 'jquery';
import validate from 'jquery-validation';
import { InputFactory } from '../../_modules/input/input';
import preloader from '../../_scripts/libs/preloader/preloader';
import Modal from '../../_modules/modal/modal';

class Form {
	constructor(el, selector, params) {

		if (!el) return false;

		this.form = el;

		const defaultParams = {
			method: this.form.method,
			action: this.form.action
		};

		this.params = Object.assign({}, defaultParams, params);

		this._init();
	}

	_init() {
		this._initElements();
		this._initFormValidation();
        this._blurInputs();
	}

	_initElements() {
		this.inputs = new InputFactory({ selector: 'data-input' });
	}

	_initFormValidation() {

		$.validator.setDefaults({
		    showErrors: function (errorMap, errorList) {
		        this.errorList = $.grep(errorList, function (error) {
		            return error.element !== undefined;
		        });
		        this.defaultShowErrors();
		    }
		});

		$.validator.messages = {
			required: 'Поле обязательно для заполнения',
			email: 'Введите корректный email'
		};

		$.validator.addMethod('tel', function (value, element) {
			return this.optional(element) || element.inputmask.isValid();
		}, 'Введите корректный номер телефона');

		this.validator = $(this.form).validate({
			submitHandler: () => this._onFormSubmit(),
		});

	}

	_onFormSubmit() {
		let formData = this.params.method.toLowerCase() === 'get' ? $(this.form).serialize() : new FormData(this.form),
			preloaderTimeout;

		let that = this;

		$.ajax({
			url: this.params.action,
			data: formData,
			method: this.params.method,
			dataType: 'json',
			processData: false,
			contentType: false,
			beforeSend: () => {
				preloaderTimeout = setTimeout(() => {
					$(this.form).preloader('start');
				}, 100);
                this._blurInputs();
			},
			success: (res) => {

				clearTimeout(preloaderTimeout);
				$(this.form).preloader('stop');

				if (res.success) {

					let modalSuccess = this.form.dataset.successPopup;

					if (modalSuccess) {
						$(modalSuccess).modal({
							showClose: false,
						});
					}

					this.reset();

					if (this.params.callback) this.params.callback();
				}

                if (res.errors && res.errors.length) {

					this._updateErrors(res.errors);

                }

				if (res.error) {
					this._updateError(res.error);
				}

			},
			error: (res) => {

			}
		});

	}

    _updateErrors(errors) {

        errors.forEach(error => {
            for (let errorName in error) {
                this.inputs.inputs.forEach(input => {
                    if (input.getName() === errorName) {
                        input.setError(error[errorName])

						let errorIndex = errors.indexOf(error);
						errorIndex > -1 ? errors.splice(errorIndex, 1) : false

                    }
                });
            }
        });

		if(!errors.length) return false;

		let formErrors = $(this.form).children('[form-errors]');

		$(this.form).children('[form-errors]').html('').hide();

		errors.forEach(error => {
			let errorContainer = document.createElement('p');
			for (let errorName in error) {
				errorContainer.innerHTML = error[errorName];
				$(formErrors).append(errorContainer);
			}
		});

		$(formErrors).show();

    }

	_updateError(error) {
		$(this.form).children('[form-errors]').html(error);
	}

    _blurInputs() {
        this.inputs.inputs.forEach(input => {
  	         if (input.blur) input.blur();
        });
    }

	reset() {
		this.inputs.inputs.forEach(input => {
			input.reset();
		});
	}

}

class Forms {
	constructor(props) {
        if (!props.root) return false;
		[].slice.call(document.querySelectorAll('[data-form]')).forEach(el => {
			new Form(el, props.selector, props.params);
		});
	}
}

export { Forms, Form }
